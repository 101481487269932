/**
 * = Icon boxes
 */

.icon {
	text-align: center;
	display: inline-flex;
	@include align-items(center);
	@include justify-content(center);

	span, svg {
		font-size: $icon-size;
	}

	&.icon-xs{
		span, svg {
			font-size: $icon-size-xs;
		}
	}

	&.icon-sm{
		span, svg {
			font-size: $icon-size-sm;
		}
	}

	&.icon-lg{
		span, svg {
			font-size: $icon-size-xl;
		}
	}

	&.rounded-circle{
		.icon-bordered{
			@include border-radius($circle-radius);
		}
	}

	&.organic-radius{
		.icon-bordered{
			@include border-radius($organic-radius);
		}
	}
}

// Icons included in shapes
.icon-shape {
	width: $icon-shape;
	height: $icon-shape;
	text-align: center;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	@include border-radius($border-radius);

	span, svg {
		font-size: $icon-size;
	}

	&.icon-sm {
		width: $icon-shape-sm;
		height: $icon-shape-sm;
		span, svg {
			font-size: $icon-size-sm;
		}
	}
	
	&.icon-lg{
		width: $icon-shape-lg;
		height: $icon-shape-lg;
		span, svg {
			font-size: $icon-size-xl;
		}
	}
}

.github-big-icon{
	position: absolute;
    right: -255px;
    top: 75px;
	span{
		font-size: 800px;
    	opacity: .1;
	}
}
 
//Colors for icons
@each $color, $value in $theme-colors {
	.icon-shape-#{$color} {
		@include icon-shape-variant(theme-color($color));
	}
	.icon-#{$color} {
		span{
			color:$value;
		}
	}

	.fill-#{$color}{
		fill:$value;
	}
}
